import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function StaticDataTable(props) {
    const classes = useStyles();

    const { rows, cols, size } = props

    console.log(rows)

    console.log(cols)

    return (
        <TableContainer style={{ marginBottom: 10 }} component={Paper}>
            <Table className={classes.table} size={size} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {cols.map((col, key) => <TableCell key={col.name + key}>{col.name}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            {cols.map((col, colIndex) => <TableCell key={index + colIndex} component="th" scope="row">
                                {row[col.field]}
                            </TableCell>)}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
