import React, { useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import app, { firebase } from "../../configurations/firebase";
import { AuthContext } from "../AuthContext/AuthContext";

export const LocationContext = React.createContext({});

export const LocationProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [locations, setLocations] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const addLocationData = (dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("locations")
      .add({
        ...dataObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: profile,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        enqueueSnackbar("Document written", { variant: "success" });
        getLocationDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const updateLocationData = (dataId, dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("locations")
      .doc(dataId)
      .update({
        ...dataObj,
        modifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        modifiedBy: profile,
      })
      .then(() => {
        console.log("Document successfully written!");
        enqueueSnackbar("Document successfully updated!", {
          variant: "success",
        });
        getLocationDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const deleteLocationData = (dataId) => {
    setLoading(false)
    app
      .firestore()
      .collection("locations")
      .doc(dataId)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        enqueueSnackbar("Document successfully deleted!", {
          variant: "success",
        });
        getLocationDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error removing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getLocationDataAll = () => {
    setLoading(true)
    app
      .firestore()
      .collection("locations")
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setLocations(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };


  useEffect(() => {
    getLocationDataAll()
  }, []);

  return (
    <LocationContext.Provider
      value={{
        loading,
        locations,
        selectedLocation,
        setSelectedLocation,
        addLocationData,
        updateLocationData,
        deleteLocationData,
        getLocationDataAll,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
