import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// const firebaseOptions = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const firebaseOptions = {
  apiKey: "AIzaSyDRA0jpAUVa86cAOef2KyXOcHqlEIHrv1M",
  authDomain: "cwms-dev-25b26.firebaseapp.com",
  projectId: "cwms-dev-25b26",
  storageBucket: "cwms-dev-25b26.appspot.com",
  messagingSenderId: "219739503401",
  appId: "1:219739503401:web:9add87092b74f940d4d538",
  measurementId: "G-QQXG4G35G1"
};

const app = firebase.initializeApp(firebaseOptions);
const storage = app.storage().ref();

if (window.location.hostname === "localhost") {
  console.log(
    "testing locally -- hitting local functions and firestore emulators"
  );
  app.auth().useEmulator("http://localhost:9098/");
  app.firestore().useEmulator("localhost", 8082);
  app.functions().useEmulator("localhost", 5003);
  app.storage().useEmulator("localhost", 9199);
}

export { firebase, storage };
export default app;
