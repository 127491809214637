import React, { useContext, useEffect, useState } from "react";

import { useSnackbar } from "notistack";
import app, { firebase } from "../../configurations/firebase";
import { AuthContext } from "../AuthContext/AuthContext";

export const TaskContext = React.createContext({});

export const TaskProvider = ({ children }) => {
  const { profile, role, user } = useContext(AuthContext);

  const [tasks, setTasks] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskCheckins, setSelectedTaskCheckins] = useState(null);
  const [dailyTaskCheckins, setDailyTaskCheckins] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const addTaskData = (dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("tasks")
      .add({
        ...dataObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: profile,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        enqueueSnackbar("Document written", { variant: "success" });
        getTaskDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const updateTaskData = (dataId, dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("tasks")
      .doc(dataId)
      .update({
        ...dataObj,
        modifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        modifiedBy: profile,
      })
      .then(() => {
        console.log("Document successfully written!");
        enqueueSnackbar("Document successfully updated!", {
          variant: "success",
        });
        getTaskDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const deleteTaskData = (dataId) => {
    setLoading(false)
    app
      .firestore()
      .collection("tasks")
      .doc(dataId)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        enqueueSnackbar("Document successfully deleted!", {
          variant: "success",
        });
        getTaskDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error removing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getTaskDataAll = () => {
    setLoading(true)
    app
      .firestore()
      .collection("tasks")
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        console.log(arr)
        setTasks(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getTaskDataAllByClient = () => {
    setLoading(true)
    app
      .firestore()
      .collection("tasks")
      .where("client.id", '==', user.uid)
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        console.log(arr)
        setTasks(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getTaskCheckingData = (taskId) => {
    setLoading(true)
    console.log(taskId)
    app
      .firestore()
      .collection("tasks")
      .doc(taskId)
      .collection('checkins')
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        console.log(arr)
        setSelectedTaskCheckins(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getTaskCheckingDataSync = async (taskId) => {
    console.log(taskId)
    try {
      const response = await app
        .firestore()
        .collection("tasks")
        .doc(taskId)
        .collection('checkins')
        .orderBy('createdAt', 'desc')
        .get()
      const arr = [];
      response.docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
      });
      return arr;
    } catch (e) {
      console.log(e)
      return []
    }
  };


  const getLast10TaskCheckingData = (taskId) => {
    setLoading(true)
    console.log(taskId)
    app
      .firestore()
      .collection("tasks")
      .doc(taskId)
      .collection('checkins')
      .orderBy('createdAt', 'desc')
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        console.log(arr)
        setSelectedTaskCheckins(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getTaskData = (userObj) => {
    setLoading(true)
    console.log(userObj);
    app
      .firestore()
      .collection("tasks")
      // .where("modifiedAt", '<=', userObj.searchEndTime)
      // .where("modifiedAt", '>=', userObj.searchStartTime)
      .where("client.id", '==', userObj.searchClient)
      .orderBy('modifiedAt', 'desc')
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) { setLoading(false) }
        setDailyTaskCheckins(null);
        let tempDailyChekcins = [];
        querySnapshot.forEach((taskDoc) => {
          app
            .firestore()
            .collection("tasks")
            .doc(taskDoc.id)
            .collection('checkins')
            .where("createdAt", '<=', userObj.searchEndTime)
            .where("createdAt", '>=', userObj.searchStartTime)
            .orderBy('createdAt', 'desc')
            .get()
            .then((querySnapshot) => {
              const arr = [];
              querySnapshot.forEach((checkinDoc) => {
                const taskData = taskDoc.data()
                const checkinData = checkinDoc.data();
                arr.push({ id: taskDoc.id, name: taskData.name, startTime: checkinData.startTime.toDate().toString(), endTime: checkinData.endTime.toDate().toString(), createdBy: checkinData.createdBy });
              });
              console.log(arr)
              tempDailyChekcins = tempDailyChekcins.concat(arr);
              setDailyTaskCheckins(tempDailyChekcins)
              setLoading(false)
            })
            .catch((e) => {
              console.log("Error", e);
              enqueueSnackbar(e.message, { variant: "error" });
              setLoading(false)
            });
        });

      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };


  useEffect(() => {
    if (role === 0) {
      getTaskDataAll()
    } else if (role === 1) {
      getTaskDataAllByClient()
    }
  }, []);

  return (
    <TaskContext.Provider
      value={{
        loading,
        tasks,
        selectedTask,
        setSelectedTask,
        selectedTaskCheckins,
        setSelectedTaskCheckins,
        dailyTaskCheckins,
        addTaskData,
        updateTaskData,
        deleteTaskData,
        getTaskCheckingData,
        getLast10TaskCheckingData,
        getTaskCheckingDataSync,
        getTaskData
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
