import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { AppHeader, AppSidebar } from "../../components";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    width: "100%",
  },
  overlay: {
    'position': 'fixed',
    'display': 'flex',
    'width': '100%',
    'height': '100%',
    'top': 0,
    'left': 0,
    'right': 0,
    'bottom': 0,
    'backgroundColor': 'rgba(0,0,0,0.9)',
    'zIndex': 2,
    'cursor': 'pointer',
  }
}));

export default function Main({ children }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeader handleDrawerOpen={handleDrawerOpen} open={open} />
      <AppSidebar handleDrawerClose={handleDrawerClose} open={open} />
      {children}
    </div>
  );
}
