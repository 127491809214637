import { Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";

import DataTable from "../../ui/DataTable/DataTable";

import { LocationContext } from "../../context/LocationContext/LocationContext";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";

import LocationForm from "../LocationForm";

export default function LocationTable() {
  const { locations, loading, setSelectedLocation, deleteLocationData } = useContext(LocationContext)

  const [showModal, setShowModal] = useState(false);

  const handleEditAction = (rowData) => {
    setSelectedLocation(rowData)
    setShowModal(true);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <DataTable
          search={false}
          isLoading={loading}
          grouping={true}
          exportButton={true}
          paging={true}
          title={'Locations'}
          columns={[
            { title: "Name", field: "name" },
            { title: "Desc.", field: "description" },
            { title: "Address", field: "address" },
            { title: "Client Name", field: "client.name" },
            { title: "Created By", field: "createdBy.name" },
            { title: "Modified By", field: "modifiedBy.name" },
          ]}
          data={locations ? locations : []}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                deleteLocationData(oldData.id);
              }),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Location",

              onClick: (event, rowData) => handleEditAction(rowData),
            },
          ]}
        />
      </Grid>

      {/* showModal */}
      <CustomDialog
        maxWidth={"md"}
        title={"Edit Location"}
        buttonCaption="Edit"
        open={showModal}
        setOpen={setShowModal}
      >
        <LocationForm
          setOpen={setShowModal}
          action={1}
        />
      </CustomDialog>
    </Grid>
  );
}
