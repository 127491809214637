import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";

import { ClientContext } from "../../context/ClientContext/ClientContext";

import ClientForm from "../ClientForm";

export default function ClientAdd() {

    const [showModal, setShowModal] = useState(false);

    const { loading } = useContext(ClientContext)

    const handleAddAction = () => {
        setShowModal(true);
    };

    return (
        <>
            <Button disabled={loading} variant="contained" color='primary' onClick={handleAddAction}>Add New</Button>

            <CustomDialog
                maxWidth={"md"}
                title={"Add Client"}
                buttonCaption="Add"
                open={showModal}
                setOpen={setShowModal}
            >
                <ClientForm setOpen={setShowModal} action={0} />
            </CustomDialog>
        </>
    );
}
