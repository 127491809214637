import { Button, Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import Paper from "@material-ui/core/Paper";

import DataTable from "../../ui/DataTable/DataTable";

import { TaskContext } from "../../context/TaskContext/TaskContext";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import TaskForm from "../TaskForm";
import CustomDateAndTimePicker from "../../ui/CustomDateAndTimePicker/CustomDateAndTimePicker";
import QrCodeView from "../QrCodeView";
import { AuthContext } from "../../context/AuthContext/AuthContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  searchButton: {
    height: '100%'
  }
}));

export default function TaskTable() {
  const classes = useStyles();

  const { tasks, loading, deleteTaskData, setSelectedTask } = useContext(TaskContext)

  const { role } = useContext(AuthContext)

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  let today = new Date()
  const defaultTime = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + today.getDate()).slice(-2)
    + "T" + ('0' + today.getUTCHours()).slice(-2)
    + ":" + ('0' + today.getUTCMinutes()).slice(-2)
    + ":" + ('0' + today.getUTCSeconds()).slice(-2)

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [startTime, setStartTime] = useState(defaultTime)
  const [endTime, setEndTime] = useState(defaultTime)

  const handleEditAction = (rowData) => {
    setSelectedTask(rowData)
    setShowModal(true);
  };

  const handleDownloadAction = (rowData) => {
    console.log(rowData)
    setSelectedTask(rowData)
    setShowModal2(true);
  };

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <CustomDateAndTimePicker label="Start Time" onChange={(e) => setStartTime(e.target.value)} value={startTime} defaultTime={defaultTime} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <CustomDateAndTimePicker label="End Time" onChange={(e) => setEndTime(e.target.value)} value={endTime} defaultTime={defaultTime} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Button variant="contained" color='primary' fullWidth className={classes.searchButton} onClick={() => console.log("searching")}>Search</Button>
        </Paper>
      </Grid> */}

      <Grid item xs={12} md={12} lg={12}>
        <DataTable
          search={false}
          isLoading={loading}
          grouping={true}
          exportButton={true}
          paging={true}
          title={'Tasks'}
          columns={[
            { title: "Task ID", field: "id" },
            { title: "Name", field: "name" },
            // { title: "Is Scheduled", field: "isScheduled" },
            // { title: "Desc.", field: "description" },
            // { title: "Start Time", field: "startTime" },
            // { title: "End Time", field: "endTime" },
            // { title: "Status", field: "status" },
            // { title: "QR Enabled", field: "qrEnabled" },
            { title: "Client", field: "client.name" },
            { title: "Created By", field: "createdBy.name" },
            { title: "Modified By", field: "modifiedBy.name" },
          ]}
          data={tasks ? tasks : []}
          editable={role === 0 && {
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                resolve();
                deleteTaskData(oldData.id);
              }),
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Task",

              onClick: (event, rowData) => handleEditAction(rowData),
            },
            {
              icon: "download",
              tooltip: "Download QR",

              onClick: (event, rowData) => handleDownloadAction(rowData),
            },
          ]}
        />
      </Grid>

      {/* showModal */}
      <CustomDialog
        maxWidth={"md"}
        title={"Edit Task"}
        buttonCaption="Edit"
        open={showModal}
        setOpen={setShowModal}
      >
        <TaskForm
          setOpen={setShowModal}
          action={1}
        />
      </CustomDialog>
      <CustomDialog
        maxWidth={"sm"}
        title={"QR Code"}
        buttonCaption="Download"
        open={showModal2}
        setOpen={setShowModal2}
      >
        <QrCodeView
          setOpen={setShowModal2}
        />
      </CustomDialog>
    </Grid>
  );
}
