import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { App, Signin, NotFound, Task, Location, Employee, Administrator, Report, Client } from "./containers";
import { AuthContext, AuthProvider } from "./context/AuthContext/AuthContext";
import { CustomThemeContext } from "./context/CustomThemeContext/CustomThemeContext";
import { MuiThemeProvider } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TaskProvider } from "./context/TaskContext/TaskContext";
import { EmployeeProvider } from "./context/EmployeeContext/EmployeeContext";
import { AdministratorProvider } from "./context/AdministratorContext/AdministratorContext";
import { ClientProvider } from "./context/ClientContext/ClientContext";

import COMPANY_LOGO from './assests/companyLogo2.png';
import { LocationProvider } from "./context/LocationContext/LocationContext";

export default function Routes() {
  const { customTheme } = useContext(CustomThemeContext);

  return (
    <MuiThemeProvider theme={customTheme}>
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/">
              <Signin />
            </Route>
            <PrivateRoute path="/main">
              <AdministratorProvider>
                <EmployeeProvider>
                  <ClientProvider>
                    <LocationProvider>
                      <TaskProvider>
                        <App>
                          <Switch>
                            <PrivateRoute path="/main/tasks">
                              <Task />
                            </PrivateRoute>
                            <PrivateRoute path="/main/locations">
                              <Location />
                            </PrivateRoute>
                            <PrivateRoute path="/main/clients">
                              <Client />
                            </PrivateRoute>
                            <PrivateRoute path="/main/administrators">
                              <Administrator />
                            </PrivateRoute>
                            <PrivateRoute path="/main/employees">
                              <Employee />
                            </PrivateRoute>
                            <PrivateRoute path="/main/reports">
                              <Report />
                            </PrivateRoute>
                          </Switch>
                        </App>
                      </TaskProvider>
                    </LocationProvider>
                  </ClientProvider>
                </EmployeeProvider>
              </AdministratorProvider>
            </PrivateRoute>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </AuthProvider>
      </Router>
    </MuiThemeProvider>
  );
}

const PrivateRoute = ({ children, ...rest }) => {
  const { user, initializing } = useContext(AuthContext);

  if (initializing) {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        <LinearProgress />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "99vh",
          }}
        >
          {/* Loading... */}
          <img alt="companyLogo" id="logo" src={COMPANY_LOGO} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Route
        {...rest}
        render={({ location }) =>
          user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
};
