import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { LocationContext } from "../../context/LocationContext/LocationContext";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";

import LocationForm from "../LocationForm";

export default function LocationAdd() {

    const [showModal, setShowModal] = useState(false);

    const { loading } = useContext(LocationContext)

    const handleAddAction = () => {
        setShowModal(true);
    };

    return (
        <>
            <Button disabled={loading} variant="contained" color='primary' onClick={handleAddAction}>Add New</Button>

            <CustomDialog
                maxWidth={"md"}
                title={"Add Location"}
                buttonCaption="Add"
                open={showModal}
                setOpen={setShowModal}
            >
                <LocationForm setOpen={setShowModal} action={0} />
            </CustomDialog>
        </>
    );
}
