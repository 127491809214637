import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, Typography } from "@material-ui/core";

import COMPANY_LOGO from '../../assests/companyLogo2.png'

//context
import { TaskContext } from "../../context/TaskContext/TaskContext";

import QRCode from "react-qr-code";
import { printDocument } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    text: { color: 'black', textAlign: 'center' },
    highlightedText: { color: 'black', textAlign: 'center', fontWeight: 'bold' },
    subText: { color: 'grey', textAlign: 'center' },
    title: { color: 'black', textAlign: 'center' }
}));

export default function QrCodeView(props) {
    const classes = useStyles();

    const { selectedTask, loading } = useContext(TaskContext);

    const { setOpen } = props;

    const OnDownload = async () => {
        printDocument("qr_code", `${selectedTask.id}_qr_code`, 'middle')
    };

    console.log(selectedTask)

    return (
        <>
            <Grid id="qr_code" container spacing={2} style={{ backgroundColor: 'white' }}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography className={classes.highlightedText}>Address</Typography>
                    <Typography className={classes.subText}>
                        {selectedTask.client && `${selectedTask.client.name}, ${selectedTask.client.address}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography className={classes.title} variant="h2" gutterBottom>
                        Scan Here
                    </Typography>
                </Grid>

                <Grid item xs={4} md={4} lg={4}>

                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                    {selectedTask && <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={selectedTask.id}
                        viewBox={`0 0 256 256`}
                    />}
                </Grid>
                <Grid item xs={4} md={4} lg={4}>

                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography className={classes.title}>{selectedTask.id}</Typography>
                    <Typography className={classes.title}>({selectedTask.name})</Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography className={classes.text}>360 Degrees Facility Services</Typography>
                    <Typography className={classes.highlightedText}>Employees Only</Typography>
                </Grid>
                <Grid item xs={3} md={3} lg={3}></Grid>
                <Grid item xs={6} md={6} lg={6} container justifyContent="center" alignItems="center">
                    <img style={{ maxWidth: 150 }} src={COMPANY_LOGO} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}></Grid>


            </Grid>
            <Button
                startIcon={loading && <CircularProgress size={20} />}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={OnDownload}
            >
                Download
            </Button>
        </>
    );
}
