import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, Typography } from "@material-ui/core";

// ui
import TextInput from "../../ui/TextInput/TextInput";
import CustomDateAndTimePicker from "../../ui/CustomDateAndTimePicker/CustomDateAndTimePicker";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";

//context
import { TaskContext } from "../../context/TaskContext/TaskContext";
import { LocationContext } from "../../context/LocationContext/LocationContext";
import { EmployeeContext } from "../../context/EmployeeContext/EmployeeContext";

//validate
import { printDocument, downloadCsv, validate } from "../../utils/utils";

// validate schema
import schema from "../../validation/task";

// constants
import { TaskStatus } from "../../constants/TaskStatus";

// libs
import QRCode from "react-qr-code";
import StaticDataTable from "../../ui/StaticDataTable/StaticDataTable";
import SingleLineImageList from "../../ui/SingleLineImageList/SingleLineImageList";
import CustomRadioGroup from "../../ui/CustomRadioGroup/CustomRadioGroup";
import { TaskFrequencies } from "../../constants/TaskFrequencies";
import { Days } from "../../constants/Days";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ReportView(props) {
  const classes = useStyles();

  const { selectedTask, addTaskData, updateTaskData, loading, selectedTaskCheckins, getTaskCheckingDataSync } = useContext(TaskContext);
  const { locations } = useContext(LocationContext)
  const { employees } = useContext(EmployeeContext)

  const { setOpen, readOnly } = props;

  let OnSubmit = async () => {
    // printDocument('report', `${selectedTask.id}_report`, 'start')
    const rows = [["Task Id", "Task Name", "Client Name", "Client Address", "Employee Name",
      "Employee Phone Number", "Employee Email", "Employee Check-In Time", "Employee Check-Out Time"]]

    const checkinData = await getTaskCheckingDataSync(selectedTask.id);
    console.log(checkinData)

    checkinData.forEach(checkin => (rows.push(
      [selectedTask.id,
      selectedTask.name,
      selectedTask.client ? selectedTask.client.name : null,
      selectedTask.client ? selectedTask.client.address : null,
      checkin.createdBy ? checkin.createdBy.name : null,
      checkin.createdBy ? checkin.createdBy.phoneNumber : null,
      checkin.createdBy ? checkin.createdBy.email : null,
      checkin.startTime ? checkin.startTime.toDate().toString() : null,
      checkin.endTime ? checkin.endTime.toDate().toString() : null,

      ])));

    console.log(rows)

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    console.log(csvContent)

    downloadCsv(`${selectedTask.id}_report`, csvContent)
    setOpen(false)

  };

  const readOnlyInputProps = { readOnly: true };


  // pre populate data
  const populateData = () => {
    // if (action === 1) {
    //   setName(selectedTask.name)
    //   setDescription(selectedTask.description)
    //   setStatus(selectedTask.status)
    //   setQrEnabled(selectedTask.qrEnabled)
    //   setStartTime(selectedTask.startTime)
    //   setEndTime(selectedTask.endTime)
    //   setCoWorkers(selectedTask.coWorkers ? selectedTask.coWorkers.map(coWorker => coWorker.id) : [])
    //   setCreatedBy(selectedTask.createdBy)
    //   setCreatedAt(selectedTask.createdAt)
    //   setModifiedBy(selectedTask.modifiedBy)
    //   setModifiedAt(selectedTask.modifiedAt)
    // }
  }

  useEffect(() => {
    populateData()
  }, [])

  const clientHeaders = [
    { field: 'name', name: 'Name' },
    { field: 'email', name: 'Email' },
    { field: 'address', name: 'Address' },
    { field: 'phoneNumber', name: 'Phone Number' }
  ]

  const coWorkersHeaders = [
    { field: 'name', name: 'Name' },
    { field: 'email', name: 'Email' },
    { field: 'phoneNumber', name: 'Phone Number' },
  ]

  const checkinsHeaders = [
    { field: 'startTime', name: 'Start Time' },
    { field: 'endTime', name: 'End Time' },
    { field: 'createdAt', name: 'Created At' },
    { field: 'createdBy', name: 'Created By' },
  ]

  const clientRows = [
    {
      name: selectedTask.client && selectedTask.client.name,
      email: selectedTask.client && selectedTask.client.email,
      address: selectedTask.client && selectedTask.client.address,
      phoneNumber: selectedTask.client && selectedTask.client.phoneNumber,
    }
  ]

  const coWorkersRows = selectedTask.coWorkers.map(coWorker => (

    {
      name: coWorker.name,
      email: coWorker.email,
      phoneNumber: coWorker.phoneNumber,
    }

  ))

  const checkinsRows = selectedTaskCheckins ? selectedTaskCheckins.map(checkin => (
    {
      startTime: checkin.startTime && checkin.startTime.toDate().toString(),
      endTime: checkin.endTime && checkin.endTime.toDate().toString(),
      createdAt: checkin.createdAt && checkin.createdAt.toDate().toString(),
      createdBy: checkin.createdBy && checkin.createdBy.name,
    }
  )) : []

  const imageList = selectedTask.images ? selectedTask.images.map(imageData => (
    {
      title: imageData.createdBy && imageData.createdBy.name,
      img: imageData.url && imageData.url,
      author: imageData.createdBy && imageData.createdBy.name
    }
  )) : []

  return (
    <>
      <div id="report" style={{ margin: 10 }}>
        <Grid container spacing={1}>
          <Grid item container spacing={2}>
            <Grid item xs={2} md={2} lg={2}>
              <div style={{ height: "auto" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={selectedTask ? selectedTask.id : ''}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </Grid>
            <Grid item container xs={10} md={10} lg={10}>
              <Grid item xs={12} md={12} lg={12}>
                <TextInput
                  value={selectedTask.name}
                  label="Name"
                  name="name"
                  InputProps={readOnlyInputProps}
                  variant='standard'
                />

              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TextInput
                  value={selectedTask.description}
                  label="Description"
                  name="description"
                  InputProps={readOnlyInputProps}
                  variant='standard'
                  multiline={true}
                  rows={5}
                  rowsMax={5}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <CustomRadioGroup
                row={true}
                value={selectedTask.isScheduled}
                // label="Select a custom time duration or a schedule"
                items={[{ 'value': true, 'label': 'Select a custom schedule' }, { 'value': false, 'label': 'Select a custom date & time' }]}
              />
            </Grid>

            {selectedTask && selectedTask.time && <>
              {selectedTask.isScheduled ?
                <>
                  <Grid item xs={1} md={1} lg={1}>
                    <Typography>Every</Typography>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <SelectMenu
                      data={[...Array(12).keys()].map(num => ({ 'value': (num + 1), 'label': (num + 1) }))}
                      label="Select Schedule Frequency"
                      width={"100%"}
                      value={selectedTask.time.scheduleFrequency}
                      fullWidth={true}
                      InputProps={readOnlyInputProps}
                      variant='standard'
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <SelectMenu
                      data={TaskFrequencies}
                      label="Select Schedule Frequency Type"
                      width={"100%"}
                      value={selectedTask.time.scheduleFrequencyType}
                      fullWidth={true}
                      variant='standard'
                      InputProps={readOnlyInputProps}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <SelectMenu
                      data={Days}
                      label="Select Schedule Day"
                      width={"100%"}
                      value={selectedTask.time.scheduleDay}
                      fullWidth={true}
                      variant='standard'
                      SelectProps={{ multiple: true }}
                      InputProps={readOnlyInputProps}
                    />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <CustomDateAndTimePicker InputProps={readOnlyInputProps} type="time" label="Start Time" value={selectedTask.time.scheduleStartTime} variant='standard' />
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <CustomDateAndTimePicker InputProps={readOnlyInputProps} type="time" label="Start Time" value={selectedTask.time.scheduleEndTime} variant='standard' />
                  </Grid>
                </>
                :
                <>
                  <Grid item xs={6} md={6} lg={6}>
                    <CustomDateAndTimePicker InputProps={readOnlyInputProps} type="datetime-local" label="Start Time" value={selectedTask.time.customStartTime} variant='standard' />
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <CustomDateAndTimePicker InputProps={readOnlyInputProps} type="datetime-local" label="End Time" value={selectedTask.time.customEndTime} variant='standard' />
                  </Grid>
                </>
              }
            </>}


            {/* <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={selectedTask.status}
                label="Status"
                name="stayus"
                InputProps={readOnlyInputProps}
                variant='standard'
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="caption" display="block">Task Client Details</Typography>
              <StaticDataTable rows={clientRows} cols={clientHeaders} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="caption" display="block">Co-workers Details</Typography>
              <StaticDataTable rows={coWorkersRows} cols={coWorkersHeaders} />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="caption" display="block">Last 10 Check-In/ Check-Out Details</Typography>
              {loading ? <Typography variant="caption" display="block">Loading...</Typography>
                : <StaticDataTable rows={checkinsRows} cols={checkinsHeaders} />}
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="caption" display="block">Images</Typography>
              <SingleLineImageList itemData={imageList} />
            </Grid>

            <Grid item xs={4} md={4} lg={4}>
              <TextInput
                value={selectedTask.createdBy && selectedTask.createdBy.name}
                label="Created By"
                name="createdBy"
                InputProps={readOnlyInputProps}
                variant='standard'
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <TextInput
                value={selectedTask.createdAt && selectedTask.createdAt.toDate()}
                label="Created At"
                name="createdAt"
                InputProps={readOnlyInputProps}
                variant='standard'
              />
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <TextInput
                value={selectedTask.modifiedBy && selectedTask.modifiedBy.name}
                label="Modified By"
                name="modifiedBy"
                InputProps={readOnlyInputProps}
                variant='standard'
              />
            </Grid>
            <Grid item xs={8} md={8} lg={8}>
              <TextInput
                value={selectedTask.modifiedAt && selectedTask.modifiedAt.toDate()}
                label="Modified At"
                name="modifiedAt"
                InputProps={readOnlyInputProps}
                variant='standard'
              />
            </Grid>

          </Grid>
        </Grid>
      </div>
      <Button
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={OnSubmit}
      >
        Download
      </Button>
    </>
  );
}
