import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import app, { firebase } from "../../configurations/firebase";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authLoading, setAuthLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [role, setRole] = useState(null);
  const [profile, setProfile] = useState(null);
  const [activity, setActivity] = useState(true)

  const history = useHistory();

  const location = useLocation()

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAuthLoading(true)
    setInitializing(true);
    app.auth().onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        let userDetails = await getUser(currentUser);

        let roleTemp = userDetails.isAdmin ? 0 : userDetails.roles && userDetails.roles.includes('client') ? 1 : 2;
        setRole(roleTemp);
        setProfile(userDetails);
        setAuthLoading(false)

        if (roleTemp === 0 || roleTemp === 1) {
          history.push(location.pathname !== '/' ? location.pathname : '/main/tasks');
        }
        else {
          enqueueSnackbar("You don't have permission", { variant: "error" });
        }

      } else {
        setUser(null);
      }
      setInitializing(false);
      setAuthLoading(false)
    });
  }, []);

  const updateUserAuthByAdmin = (uid, userObj) => {
    app
      .functions()
      .httpsCallable("api/updateUser")({ uid: uid, userObj: userObj })
      .then((result) => {
        console.log("Successfully updated user", result.data.uid);
        enqueueSnackbar("Successfully updated user", { variant: "success" });
      })
      .catch((e) => {
        console.log("Error updating user:", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const deleteUserAuthByAdmin = (uid) => {
    app
      .functions()
      .httpsCallable("api/deleteUser")({ uid: uid })
      .then((result) => {
        console.log("Successfully deleted user", result.data.uid);
        enqueueSnackbar("Successfully deleted user", { variant: "success" });
      })
      .catch((e) => {
        console.log("Error deleting user:", e);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const createUserByAdmin = async (userObj) => {
    setAuthLoading(true)
    try {
      let response = await app
        .functions()
        .httpsCallable("api/createUser")(userObj)
      console.log(response)
      console.log("Successfully created user", response.data);
      enqueueSnackbar("Successfully created user", { variant: "success" });
      setAuthLoading(false)
      return response.data
    } catch (e) {
      console.log("Error creating user:", e);
      enqueueSnackbar(e.message, { variant: "error" });
      setAuthLoading(false)
      return null
    }
  }

  const signIn = async (email, password) => {
    try {
      setAuthLoading(true)
      setInitializing(true)
      await app
        .auth()
        .signInWithEmailAndPassword(email, password);
      setInitializing(false)
      setAuthLoading(false)
    } catch (e) {
      setInitializing(false)
      setAuthLoading(false)
      enqueueSnackbar(e.message, { variant: "error" });
      console.log(e.message);
    }
  };

  const updateUser = (userObj, setOpen) => {
    setAuthLoading(true);
    if (userObj.dob) {
      userObj.dob = firebase.firestore.Timestamp.fromDate(userObj.dob);
    }
    app
      .firestore()
      .collection("users")
      .doc(user.uid)
      .update(userObj)
      .then(async (obj) => {
        enqueueSnackbar("Document written successfully!", {
          variant: "success",
        });
        // console.log(obj);
        setOpen(false);
        setAuthLoading(false);
        const userDetails = await getUser(user);
        setProfile(userDetails);
      })
      .catch((e) => {
        enqueueSnackbar(e.message, { variant: "error" });
        setAuthLoading(false);
      });
  };

  const updateUserCredentials = (newPassword, setOpen) => {
    setAuthLoading(true);
    user
      .updatePassword(newPassword)
      .then(function () {
        // Update successful.
        enqueueSnackbar("Update successful!", { variant: "success" });
        signOut();
        setOpen(false);
      })
      .catch((e) => {
        // An error happened.
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  const signUp = async (userObj) => {
    try {
      setInitializing(true)
      let { username, password } = userObj;
      let response = await app
        .auth()
        .createUserWithEmailAndPassword(username, password);
      userObj['dob'] = firebase.firestore.Timestamp.fromDate(userObj.dob);
      await addUser({ id: response.user.uid, ...userObj });
      await getUser(response.user);
      setRole(userObj.role);
      setProfile(userObj);
      setInitializing(false)
    } catch (e) {
      setInitializing(false)
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const addUser = async (userObj) => {
    delete userObj.password;
    try {
      await app.firestore().collection("users").doc(userObj.id).set(userObj);
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getUser = async (new_user) => {
    try {
      const response = await app
        .firestore()
        .collection("users")
        .doc(new_user.uid)
        .get();

      return { id: response.id, ...response.data() };
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const getUserByUname = async (uname) => {
    try {
      const response = await app
        .firestore()
        .collection("users")
        .where("username", "==", uname)
        .get();
      const users = [];
      response.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });

      if (!users[0]) {
        enqueueSnackbar("Username is invalid", { variant: "error" });
      }

      return users[0];
    } catch (e) {
      console.log(e.message);
      enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const signOut = () => {
    console.log('signout')
    app
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
        history.push("/");
      })
      .catch((e) => {
        console.log(e.message);
        enqueueSnackbar(e.message, { variant: "error" });
      });
  };

  useEffect(() => {
    window.addEventListener("offline", () =>
      enqueueSnackbar("Network disconnected", { variant: "error" })
    );
    window.addEventListener("blur", () => {
      setActivity(false)
    });

  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        profile,
        initializing,
        authLoading,
        signIn,
        signOut,
        signUp,
        updateUser,
        updateUserCredentials,
        getUserByUname,
        updateUserAuthByAdmin,
        createUserByAdmin,
        deleteUserAuthByAdmin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
