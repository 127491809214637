import { Button, Grid, Hidden } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";

import DataTable from "../../ui/DataTable/DataTable";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";

import { TaskContext } from "../../context/TaskContext/TaskContext";
import CustomDialog from "../../ui/CustomDialog/CustomDialog";
import TaskForm from "../TaskForm";
import CustomDateAndTimePicker from "../../ui/CustomDateAndTimePicker/CustomDateAndTimePicker";
import ReportView from "../ReportView";
import { ClientContext } from "../../context/ClientContext/ClientContext";
import { printDocument, downloadCsv, validate } from "../../utils/utils";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
  fixedHeight: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  searchButton: {
    height: '100%'
  }
}));

export default function ReportTable() {
  const classes = useStyles();

  const { tasks, loading, setSelectedTask, getTaskCheckingData, getLast10TaskCheckingData, getTaskData, dailyTaskCheckins } = useContext(TaskContext)
  const { clients } = useContext(ClientContext)
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  let today = new Date()
  const defaultTime = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + today.getDate()).slice(-2)
    + "T" + ('0' + today.getUTCHours()).slice(-2)
    + ":" + ('0' + today.getUTCMinutes()).slice(-2)
    + ":" + ('0' + today.getUTCSeconds()).slice(-2)
  const defaultTimeDayBefore = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + (today.getDate() - 1)).slice(-2)
    + "T" + ('0' + today.getUTCHours()).slice(-2)
    + ":" + ('0' + today.getUTCMinutes()).slice(-2)
    + ":" + ('0' + today.getUTCSeconds()).slice(-2)

  const defaultDate = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + today.getDate()).slice(-2)
  const defaultDateDayBefore = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + (today.getDate() - 1)).slice(-2)

  const [showModal, setShowModal] = useState(false);
  const [searchStartTime, setSearchStartTime] = useState(defaultDate)
  const [searchEndTime, setSearchEndTime] = useState(defaultTime)
  const [searchClient, setSearchClient] = useState(clients && clients[0].id)

  const handleDownloadAction = (rowData) => {
  };

  const handleDownloadAllAction = (rowData) => {
  };

  const handleShowAction = (rowData) => {
    // getTaskCheckingData(rowData.id)
    // getLast10TaskCheckingData(rowData.id)
    // setSelectedTask(rowData)
    // setShowModal(true)

    // printDocument('report', `${selectedTask.id}_report`, 'start')
    const rows = [["Task Id", "Task Name", "Employee Check-In Time", "Employee Check-Out Time", "Created By"]]

    dailyTaskCheckins.forEach(checkin => (rows.push(
      [checkin.id,
      checkin.name,
      checkin.startTime ? checkin.startTime : null,
      checkin.endTime ? checkin.endTime : null,
      checkin.createdBy ? checkin.createdBy.name : null,
      ])));

    console.log(rows)

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    console.log(csvContent)

    downloadCsv(`${searchStartTime}_${searchClient}_report`, csvContent)
  };

  const handleSearchAction = () => {
    let originalST = new Date(searchStartTime)
    let originalET = new Date(searchStartTime)
    originalST.setHours(0, 0, 0)
    let newST = new Date(originalST)
    originalET.setHours(23, 59, 59)
    let newET = new Date(originalET)
    if (!searchClient || !searchStartTime) return
    getTaskData({ searchStartTime: newST, searchClient, searchEndTime: newET })
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <CustomDateAndTimePicker type="date" label="Select Date" onChange={(e) => setSearchStartTime(e.target.value)} value={searchStartTime} defaultTime={defaultDate} />
        </Paper>
      </Grid>

      {/* <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <CustomDateAndTimePicker type="date-local" label="End Time" onChange={(e) => setSearchEndTime(e.target.value)} value={searchEndTime} defaultTime={defaultTime} />
        </Paper>
      </Grid> */}

      <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <SelectMenu
            data={clients ? clients.map(client => ({ value: client.id, label: client.name })) : []}
            label="Select Client"
            width={"100%"}
            value={searchClient}
            fullWidth={true}
            variant="outlined"
            onChange={(e) => setSearchClient(e.target.value)}
          />
        </Paper>
      </Grid>

      <Grid item xs={12} md={3} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Button variant="contained" color='primary' fullWidth className={classes.searchButton} onClick={handleSearchAction} loading={loading} disabled={loading}>Search</Button>
        </Paper>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <DataTable
          search={false}
          isLoading={loading}
          grouping={true}
          exportButton={false}
          paging={true}
          title={'Check-Ins'}
          columns={[
            { title: "Task ID", field: "id" },
            { title: "Name", field: "name" },
            { title: "Start Time", field: "startTime" },
            { title: "End Time", field: "endTime" },
            { title: "Created By", field: "createdBy.name" },
          ]}
          data={dailyTaskCheckins ? dailyTaskCheckins : []}
          actions={[
            {
              icon: "download",
              tooltip: "Download Report",
              isFreeAction: true,
              onClick: (event, rowData) => handleShowAction(rowData),
            },
          ]}
        />
      </Grid>

      {/* showModal */}
      <CustomDialog
        maxWidth={"md"}
        title={"Report"}
        buttonCaption="Edit"
        open={showModal}
        setOpen={setShowModal}
      >
        <ReportView
          setOpen={setShowModal}
          readOnly={true}
        />
      </CustomDialog>
    </Grid>
  );
}
