import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, Radio, Typography } from "@material-ui/core";

// ui
import TextInput from "../../ui/TextInput/TextInput";
import CustomDateAndTimePicker from "../../ui/CustomDateAndTimePicker/CustomDateAndTimePicker";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";

//context
import { TaskContext } from "../../context/TaskContext/TaskContext";
import { EmployeeContext } from "../../context/EmployeeContext/EmployeeContext";
import { ClientContext } from "../../context/ClientContext/ClientContext";

//validate
import { printDocument, validate } from "../../utils/utils";

// validate schema
import schema from "../../validation/task";

// constants
import { TaskStatus } from "../../constants/TaskStatus";

// libs
import QRCode from "react-qr-code";
import { AuthContext } from "../../context/AuthContext/AuthContext";
import { TaskFrequencies } from "../../constants/TaskFrequencies";
import { Days } from "../../constants/Days";
import CustomRadioGroup from "../../ui/CustomRadioGroup/CustomRadioGroup";
import { LocationContext } from "../../context/LocationContext/LocationContext";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function TaskForm(props) {
  const classes = useStyles();

  const { selectedTask, addTaskData, updateTaskData, loading } = useContext(TaskContext);
  const { clients } = useContext(ClientContext)
  const { employees } = useContext(EmployeeContext)
  const { locations } = useContext(LocationContext)
  const { role } = useContext(AuthContext)

  const { action, setOpen, readOnly } = props;

  const [error, setError] = useState({});

  let today = new Date()
  const defaultTime = today.getUTCFullYear() +
    "-" + ('0' + (today.getUTCMonth() + 1)).slice(-2)
    + "-" + ('0' + today.getDate()).slice(-2)
    + "T" + ('0' + today.getUTCHours()).slice(-2)
    + ":" + ('0' + today.getUTCMinutes()).slice(-2)
    + ":" + ('0' + today.getUTCSeconds()).slice(-2)

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [status, setStatus] = useState("readyToStart")

  const [isScheduled, setIsScheduled] = useState(true);
  const [scheduleFrequency, setScheduleFrequency] = useState(1);
  const [scheduleFrequencyType, setScheduleFrequencyType] = useState('week');
  const [scheduleDay, setScheduleDay] = useState(['monday']);
  const [scheduleStartTime, setScheduleStartTime] = useState(defaultTime);
  const [scheduleEndTime, setScheduleEndTime] = useState(defaultTime);

  const [qrEnabled, setQrEnabled] = useState(true)

  const [customStartTime, setCustomStartTime] = useState(defaultTime)
  const [customEndTime, setCustomEndTime] = useState(defaultTime)

  const [client, setClient] = useState("")
  const [location, setLocation] = useState("")
  const [coWorkers, setCoWorkers] = useState([])
  const [createdBy, setCreatedBy] = useState(null)
  const [createdAt, setCreatedAt] = useState(null)
  const [modifiedBy, setModifiedBy] = useState(null)
  const [modifiedAt, setModifiedAt] = useState(null)

  let OnSubmit = () => {
    if (role !== 0) return;
    let userData = { name: name, description: description };

    if (!validateUserData(userData)) {
      return;
    }

    let coWorkersArr = employees.filter(emp => coWorkers.includes(emp.id))
    let timeObj = isScheduled ? {
      scheduleFrequency: scheduleFrequency,
      scheduleFrequencyType: scheduleFrequencyType,
      scheduleDay: scheduleDay,
      scheduleEndTime: scheduleEndTime,
      scheduleStartTime: scheduleStartTime
    }
      : { customStartTime: customStartTime, customEndTime: customEndTime }
    userData = {
      ...userData, status: status, qrEnabled: qrEnabled, isScheduled: isScheduled, time: timeObj,
      coWorkers: coWorkersArr,
      coWorkerIds: coWorkers
    }

    let clientObj = clients.find(cli => cli.id === client)
    userData = clientObj ? { ...userData, client: clientObj } : userData;

    let locObj = locations.find(loc => loc.id === location)
    userData = locObj ? { ...userData, location: locObj } : userData;

    if (action === 0) {
      addTaskData(userData);
    }
    else {
      updateTaskData(selectedTask.id, userData)
    }
    setOpen(false)

  };

  const validateUserData = (userData) => {
    const newErrorObject = validate(
      userData,
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      setError(newErrorObject);
      return false;
    }
    return true;
  }

  // pre populate data
  const populateData = () => {
    if (action === 1) {
      setName(selectedTask.name)
      setDescription(selectedTask.description)
      setStatus(selectedTask.status)

      setIsScheduled(selectedTask.isScheduled)
      if (selectedTask.isScheduled) {
        setScheduleDay(selectedTask.time.scheduleDay || null)
        setScheduleFrequency(selectedTask.time.scheduleFrequency || null)
        setScheduleFrequencyType(selectedTask.time.scheduleFrequencyType || null)
        setScheduleStartTime(selectedTask.time.scheduleStartTime || null)
        setScheduleEndTime(selectedTask.time.scheduleEndTime || null)
      } else {
        setCustomStartTime(selectedTask.time.customStartTime || null)
        setCustomEndTime(selectedTask.time.customEndTime || null)
      }

      setQrEnabled(selectedTask.qrEnabled)

      setClient(selectedTask.client ? selectedTask.client.id : null)
      setCoWorkers(selectedTask.coWorkers ? selectedTask.coWorkers.map(coWorker => coWorker.id) : [])

      setCreatedBy(selectedTask.createdBy)
      setCreatedAt(selectedTask.createdAt)
      setModifiedBy(selectedTask.modifiedBy)
      setModifiedAt(selectedTask.modifiedAt)
    }
  }

  useEffect(() => {
    populateData()
  }, [])

  return (
    <div id="report">
      <Grid container spacing={1}>
        <Grid item container spacing={2}>
          <Grid item xs={3} md={3} lg={3}>
            <div style={{ height: "auto" }}>
              {action === 1 ? <div id="qrCode" onClick={() => printDocument('qrCode', selectedTask ? selectedTask.id : 'qrCode', 'middle')}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={selectedTask ? selectedTask.id : ''}
                  viewBox={`0 0 256 256`}
                /></div> : <Typography>QR code will appear here</Typography>}
            </div>          </Grid>
          <Grid item container xs={9} md={9} lg={9}>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Name"
                name="name"
                error={error.name}
                onFocus={() => delete error["name"]}
                onBlur={() =>
                  setError({ ...error, ...validate({ name: name }, schema) })
                }
                disabled={readOnly}
                helperText={error.name}
              />

            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextInput
                value={description}
                disabled={readOnly}
                onChange={(e) => setDescription(e.target.value)}
                multiline={true}
                rows={5}
                rowsMax={5}
                label="Description"
                name="description"
                error={error.description}
                onFocus={() => delete error["description"]}
                onBlur={() =>
                  setError({ ...error, ...validate({ description: description }, schema) })
                }
                helperText={error.description}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>

          <Grid item xs={12} md={12} lg={12}>
            <CustomRadioGroup
              row={true}
              value={isScheduled}
              onChange={() => setIsScheduled(!isScheduled)}
              label="Select a custom time duration or a schedule"
              items={[{ 'value': true, 'label': 'Select a custom schedule' }, { 'value': false, 'label': 'Select a custom date & time' }]}
            />
          </Grid>
          {isScheduled ?
            <>
              <Grid item xs={1} md={1} lg={1}>
                <Typography>Every</Typography>
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <SelectMenu
                  data={[...Array(12).keys()].map(num => ({ 'value': (num + 1), 'label': (num + 1) }))}
                  label="Select Schedule Frequency"
                  width={"100%"}
                  value={scheduleFrequency}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => setScheduleFrequency(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <SelectMenu
                  data={TaskFrequencies}
                  label="Select Schedule Frequency Type"
                  width={"100%"}
                  value={scheduleFrequencyType}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => setScheduleFrequencyType(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <SelectMenu
                  data={Days}
                  label="Select Schedule Day"
                  width={"100%"}
                  value={scheduleDay}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(e) => setScheduleDay(e.target.value)}
                  SelectProps={{ multiple: true }}
                />
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <CustomDateAndTimePicker type="time" label="Start Time" onChange={(e) => setScheduleStartTime(e.target.value)} value={scheduleStartTime} defaultTime={defaultTime} />
              </Grid>
              <Grid item xs={6} md={2} lg={2}>
                <CustomDateAndTimePicker type="time" label="Start Time" onChange={(e) => setScheduleEndTime(e.target.value)} value={scheduleEndTime} defaultTime={defaultTime} />
              </Grid>
            </>
            :
            <>
              <Grid item xs={6} md={6} lg={6}>
                <CustomDateAndTimePicker type="datetime-local" label="Start Time" onChange={(e) => setCustomStartTime(e.target.value)} value={customStartTime} defaultTime={defaultTime} />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <CustomDateAndTimePicker type="datetime-local" label="End Time" onChange={(e) => setCustomEndTime(e.target.value)} value={customEndTime} defaultTime={defaultTime} />
              </Grid>
            </>
          }


          <Grid item xs={12} md={12} lg={12}>
            <SelectMenu
              disabled={readOnly}
              data={employees ? employees.map(emp => ({ value: emp.id, label: emp.name })) : []}
              label="Select Co-Workers"
              width={"100%"}
              value={coWorkers}
              fullWidth={true}
              variant="outlined"
              onChange={(e) => setCoWorkers(e.target.value)}
              SelectProps={{ multiple: true }}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <SelectMenu
              disabled={readOnly}
              data={locations ? locations.map(loc => ({ value: loc.id, label: loc.name })) : []}
              label="Select Location"
              width={"100%"}
              value={location}
              fullWidth={true}
              variant="outlined"
              onChange={(e) => setLocation(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <SelectMenu
              disabled={readOnly}
              data={clients ? clients.map(client => ({ value: client.id, label: client.name })) : []}
              label="Select Client"
              width={"100%"}
              value={client}
              fullWidth={true}
              variant="outlined"
              onChange={(e) => setClient(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <SelectMenu
              disabled={readOnly}
              data={TaskStatus}
              label="Select Status"
              width={"100%"}
              value={status}
              fullWidth={true}
              variant="outlined"
              onChange={(e) => setStatus(e.target.value)}
            />
          </Grid>

          {/* {(action === 1 || readOnly) && <Grid item xs={12} md={12} lg={12}>
            <Typography style={{ color: 'grey' }} variant="caption" display="block">Co-workers Details</Typography>
            <StaticDataTable size="small" rows={selectedTask.coWorkers ? selectedTask.coWorkers : []} cols={['name', 'email', 'phoneNumber', 'checkIn', 'checkOut']} />
          </Grid>} */}

          <Grid item xs={4} md={4} lg={4}>
            <TextInput
              value={createdBy && createdBy.name}
              label="Created By"
              name="createdBy"
              disabled={true}
            />
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <TextInput
              value={createdAt && createdAt.toDate()}
              label="Created At"
              name="createdAt"
              disabled={true}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <TextInput
              value={modifiedBy && modifiedBy.name}
              label="Modified By"
              name="modifiedBy"
              disabled={true}
            />
          </Grid>
          <Grid item xs={8} md={8} lg={8}>
            <TextInput
              value={modifiedAt && modifiedAt.toDate()}
              label="Modified At"
              name="modifiedAt"
              disabled={true}
            />
          </Grid>

        </Grid>
      </Grid>
      {!readOnly && <Button
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={OnSubmit}
      >
        {action === 1 ? "Update Task" : "Add Task"}
      </Button>}
    </div>
  );
}
