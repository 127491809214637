import Joi from "joi-browser";

let schema = Joi.object().keys({
  name: Joi.string().required(),
  description: Joi.string().required(),
  address: Joi.string().required(),
  postalCode: Joi.string().required(),

});
schema = schema.optionalKeys('name', 'description', 'address', 'postalCode');
export default schema;
