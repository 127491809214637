import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider } from "@material-ui/core";

// ui
import TextInput from "../../ui/TextInput/TextInput";
import CustomDateAndTimePicker from "../../ui/CustomDateAndTimePicker/CustomDateAndTimePicker";
import SelectMenu from "../../ui/SelectMenu/SelectMenu";

//context
import { LocationContext } from "../../context/LocationContext/LocationContext";

//validate
import { validate } from "../../utils/utils";

// validate schema
import schema from "../../validation/location";
import { ClientContext } from "../../context/ClientContext/ClientContext";

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LocationForm(props) {
  const classes = useStyles();

  const { selectedLocation, addLocationData, updateLocationData, loading } = useContext(LocationContext);
  const { clients } = useContext(ClientContext);

  const { action, setOpen } = props;

  const [error, setError] = useState({});

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [address, setAddress] = useState("")
  const [client, setClient] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [createdAt, setCreatedAt] = useState(null)
  const [modifiedBy, setModifiedBy] = useState(null)
  const [modifiedAt, setModifiedAt] = useState(null)


  const OnSubmit = () => {
    let userData = { name: name, description: description, address: address };
    if (!validateUserData(userData)) {
      return;
    }

    let clientObj = clients.find(cli => cli.id === client)
    userData = clientObj ? { ...userData, client: clientObj } : userData;

    if (action === 0) {
      addLocationData(userData)
    }
    else {
      updateLocationData(selectedLocation.id, userData)
    }
    setOpen(false)
  };

  const validateUserData = (userData) => {
    const newErrorObject = validate(
      userData,
      schema
    );
    if (Object.keys(newErrorObject).length > 0) {
      setError(newErrorObject);
      return false;
    }
    return true;
  }

  // pre populate data
  const populateData = () => {
    if (selectedLocation) {
      setName(selectedLocation.name)
      setDescription(selectedLocation.description)
      setAddress(selectedLocation.address)
      setClient(selectedLocation.client ? selectedLocation.client.id : null)
      setCreatedBy(selectedLocation.createdBy)
      setCreatedAt(selectedLocation.createdAt)
      setModifiedBy(selectedLocation.modifiedBy)
      setModifiedAt(selectedLocation.modifiedAt)
    }
  }

  useEffect(() => {
    populateData()
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            name="name"
            error={error.name}
            onFocus={() => delete error["name"]}
            onBlur={() =>
              setError({ ...error, ...validate({ name: name }, schema) })
            }
            helperText={error.name}
          />

        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline={true}
            rows={5}
            rowsMax={5}
            label="Description"
            name="description"
            error={error.description}
            onFocus={() => delete error["description"]}
            onBlur={() =>
              setError({ ...error, ...validate({ description: description }, schema) })
            }
            helperText={error.description}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <TextInput
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            multiline={true}
            rows={5}
            rowsMax={5}
            label="Address"
            name="address"
            error={error.address}
            onFocus={() => delete error["address"]}
            onBlur={() =>
              setError({ ...error, ...validate({ address: address }, schema) })
            }
            helperText={error.address}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <SelectMenu
            data={clients ? clients.map(client => ({ value: client.id, label: client.name })) : []}
            label="Select Client"
            width={"100%"}
            value={client}
            fullWidth={true}
            variant="outlined"
            onChange={(e) => setClient(e.target.value)}
          />
        </Grid>


        <Grid item xs={4} md={4} lg={4}>
          <TextInput
            value={createdBy && createdBy.name}
            label="Created By"
            name="createdBy"
            disabled={true}
          />
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          <TextInput
            value={createdAt && createdAt.toDate()}
            label="Created At"
            name="createdAt"
            disabled={true}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <TextInput
            value={modifiedBy && modifiedBy.name}
            label="Modified By"
            name="modifiedBy"
            disabled={true}
          />
        </Grid>
        <Grid item xs={8} md={8} lg={8}>
          <TextInput
            value={modifiedAt && modifiedAt.toDate()}
            label="Modified At"
            name="modifiedAt"
            disabled={true}
          />
        </Grid>

      </Grid>
      <Button
        startIcon={loading && <CircularProgress size={20} />}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={OnSubmit}
      >
        {action === 1 ? "Update Location" : "Add Location"}
      </Button>
    </>
  );
}
