import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import app, { firebase } from "../../configurations/firebase";
import { AuthContext } from "../AuthContext/AuthContext";

export const AdministratorContext = React.createContext({});

export const AdministratorProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [administrators, setAdministrators] = useState(null);
  const [selectedAdministrator, setSelectedAdministrator] = useState(null);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const addAdministratorData = (dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("users")
      .add({
        ...dataObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: profile,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        enqueueSnackbar("Document written", { variant: "success" });
        getAdministratorDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const setAdministratorData = (dataId, dataObj) => {
    setLoading(true)
    console.log(dataId, dataObj);
    app
      .firestore()
      .collection("users")
      .doc(dataId)
      .set({
        ...dataObj,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        createdBy: profile,
      })
      .then(() => {
        console.log("Document written with ID: ", dataId);
        enqueueSnackbar("Document written", { variant: "success" });
        getAdministratorDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error adding document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const updateAdministratorData = (dataId, dataObj) => {
    setLoading(true)
    app
      .firestore()
      .collection("users")
      .doc(dataId)
      .update({
        ...dataObj,
        modifiedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        modifiedBy: profile,
      })
      .then(() => {
        console.log("Document successfully written!");
        enqueueSnackbar("Document successfully updated!", {
          variant: "success",
        });
        getAdministratorDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error writing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const deleteAdministratorData = (dataId) => {
    setLoading(false)
    app
      .firestore()
      .collection("users")
      .doc(dataId)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
        enqueueSnackbar("Document successfully deleted!", {
          variant: "success",
        });
        getAdministratorDataAll()
        setLoading(false)
      })
      .catch((e) => {
        console.error("Error removing document: ", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };

  const getAdministratorDataAll = () => {
    setLoading(true)
    app
      .firestore()
      .collection("users")
      .where('isAdmin', '==', true)
      .orderBy('createdAt', 'desc')
      .get()
      .then((querySnapshot) => {
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({ id: doc.id, ...doc.data() });
        });
        setAdministrators(arr);
        setLoading(false)
      })
      .catch((e) => {
        console.log("Error", e);
        enqueueSnackbar(e.message, { variant: "error" });
        setLoading(false)
      });
  };


  useEffect(() => {
    getAdministratorDataAll()
  }, []);

  return (
    <AdministratorContext.Provider
      value={{
        loading,
        administrators,
        selectedAdministrator,
        setSelectedAdministrator,
        getAdministratorDataAll,
        addAdministratorData,
        setAdministratorData,
        updateAdministratorData,
        deleteAdministratorData
      }}
    >
      {children}
    </AdministratorContext.Provider>
  );
};
