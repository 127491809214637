import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import NightsStayOutlinedIcon from "@material-ui/icons/NightsStayOutlined";
import Brightness4OutlinedIcon from "@material-ui/icons/Brightness4Outlined";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { AuthContext } from "../../context/AuthContext/AuthContext";
import { CustomThemeContext } from "../../context/CustomThemeContext/CustomThemeContext";
import { Typography } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

export default function AppHeader(props) {
  const classes = useStyles();
  // const history = useHistory();
  const { activeTheme, toggleTheme } = useContext(CustomThemeContext);
  const { signOut, instituteData, role } = useContext(AuthContext);

  const [anchorElSettingsMenu, setAnchorElSettingsMenu] = React.useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleClickSettingsMenu = (event) => {
    setAnchorElSettingsMenu(event.currentTarget);
  };

  const handleClickProfileMenu = () => {
    setShowProfileModal(true);
    setAnchorElSettingsMenu(null);
  };

  const handleClickLogoutMenuItem = () => {
    signOut();
    setAnchorElSettingsMenu(null);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, props.open && classes.appBarShift)}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleDrawerOpen}
          className={clsx(
            classes.menuButton,
            props.open && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          Hi! {role === 0 ? "Administrator" : role === 1 ? "Client" : ""}
        </Typography>

        <IconButton onClick={toggleTheme} color="inherit">
          {activeTheme === 1 ? (
            <NightsStayOutlinedIcon />
          ) : (
            <Brightness4OutlinedIcon />
          )}
        </IconButton>

        <IconButton onClick={handleClickSettingsMenu} color="inherit">
          <AccountCircleOutlinedIcon />

        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorElSettingsMenu}
          keepMounted
          open={Boolean(anchorElSettingsMenu)}
          onClose={() => setAnchorElSettingsMenu(null)}
        >
          {/* <MenuItem onClick={handleClickProfileMenu}>Profile</MenuItem> */}
          <MenuItem onClick={handleClickLogoutMenuItem}>Logout</MenuItem>
        </Menu>

      </Toolbar>
    </AppBar>
  );
}
