import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { TaskContext } from "../../context/TaskContext/TaskContext";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";

import TaskForm from "../TaskForm";
import { AuthContext } from "../../context/AuthContext/AuthContext";

export default function TaskAdd() {

    const [showModal, setShowModal] = useState(false);

    const { loading } = useContext(TaskContext)
    const { role } = useContext(AuthContext)

    const handleAddAction = () => {
        setShowModal(true);
    };

    return (
        <>
            {role === 0 && <><Button disabled={loading} variant="contained" color='primary' onClick={handleAddAction}>Add New</Button>

                <CustomDialog
                    maxWidth={"md"}
                    title={"Add Task"}
                    buttonCaption="Add"
                    open={showModal}
                    setOpen={setShowModal}
                >
                    <TaskForm setOpen={setShowModal} action={0} />
                </CustomDialog>
            </>
            }
        </>
    );
}
