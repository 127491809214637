import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { EmployeeContext } from "../../context/EmployeeContext/EmployeeContext";

import CustomDialog from "../../ui/CustomDialog/CustomDialog";

import EmployeeForm from "../EmployeeForm";

export default function EmployeeAdd() {

    const [showModal, setShowModal] = useState(false);

    const { loading } = useContext(EmployeeContext)

    const handleAddAction = () => {
        setShowModal(true);
    };

    return (
        <>
            <Button disabled={loading} variant="contained" color='primary' onClick={handleAddAction}>Add New</Button>

            <CustomDialog
                maxWidth={"md"}
                title={"Add Employee"}
                buttonCaption="Add"
                open={showModal}
                setOpen={setShowModal}
            >
                <EmployeeForm setOpen={setShowModal} action={0} />
            </CustomDialog>
        </>
    );
}
