import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import { useHistory } from "react-router-dom";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// import { Avatar, ListItemAvatar } from "@material-ui/core";

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
// import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PlaceIcon from "@material-ui/icons/Place";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import PieChartIcon from "@material-ui/icons/PieChart";

import COMPANY_LOGO from '../../assests/companyLogo2.png';
import { Avatar, ListItemAvatar } from "@material-ui/core";
import { AuthContext } from "../../context/AuthContext/AuthContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
}));

export default function AppSidebar(props) {
  const classes = useStyles();
  const history = useHistory();

  const { role } = useContext(AuthContext);

  return (
    <React.Fragment>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !props.open && classes.drawerPaperClose
          ),
        }}
        open={props.open}
      >
        <div className={classes.toolbarIcon}>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt={"#"}
                src={COMPANY_LOGO}
              />
            </ListItemAvatar>
            <ListItemText primary="Dashboard" secondary="2.0.3" />
          </ListItem>

          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={props.handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {/* <ListItem button onClick={() => history.push("/main/dashboard")}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem> */}

          <ListItem button onClick={() => history.push("/main/tasks")}>
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Task" />
          </ListItem>

          {role === 0 && <ListItem button onClick={() => history.push("/main/clients")}>
            <ListItemIcon>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText primary="Client" />
          </ListItem>}

          {role === 0 && <ListItem button onClick={() => history.push("/main/locations")}>
            <ListItemIcon>
              <PlaceIcon />
            </ListItemIcon>
            <ListItemText primary="Location" />
          </ListItem>}

          {role === 0 && <ListItem button onClick={() => history.push("/main/administrators")}>
            <ListItemIcon>
              <GroupOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>}

          {role === 0 &&
            <ListItem button onClick={() => history.push("/main/employees")}>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="Employee" />
            </ListItem>}

          <Divider />

          <ListItem button onClick={() => history.push("/main/reports")}>
            <ListItemIcon>
              <PieChartIcon />
            </ListItemIcon>
            <ListItemText primary="Report" />
          </ListItem>

        </List>
      </Drawer>
    </React.Fragment>
  );
}
